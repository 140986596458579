import React from "react";
import "./App.css";

function App() {


  return (
    <div className="App">
      <header className="App-header">
        <h1>
          What Gifts?
        </h1>
        <p>
          Find the perfect gift for anyone!
        </p>
        <span>Comming soon! For now, checkout the <a href="https://blog.what.gifts">blog!</a></span>
      </header>
    </div>
  );
}

export default App;
